<template>
  <div>
    <base-button
      type="warning"
      icon
      size="sm"
      class="btn-link"
      @click="openModal"
    >
      <i class="tim-icons icon-zoom-split"></i>
    </base-button>

    <modal :show.sync="isOpen">
      <template slot="header">
        <div class="header">
          <img :src="driver.avatar" :alt="'Imagem do motorista '" />
          <strong>{{ driver.name }}</strong>
          <span>{{ driver.cell_phone }}</span>
        </div>
      </template>
      <div>
        <div class="divider"></div>
        <h5>Informações</h5>
        <p>{{ driver.information }}</p>

        <h6>Veículos</h6>
        <div>
          <div class="vehicle" v-for="vehicle in vehicles" :key="vehicle.id">
            <div class="row">
              <div class="col-md-4">
                <img
                  v-if="vehicle.images[0] !== undefined"
                  :src="vehicle.images[0].image_url"
                  alt="Imagem do veículo"
                />
              </div>
              <div class="col-md-8">
                <span><strong>Ano:</strong> {{ vehicle.ano }}</span>
                <span><strong>Cor:</strong> {{ vehicle.cor }}</span>
                <span><strong>Placa:</strong> {{ vehicle.placa }}</span>
                <span><strong>Marca:</strong> {{ vehicle.marca }}</span>
              </div>
            </div>
            <div class="row">
              <template v-for="(image, index) in vehicle.images">
                <img
                  v-if="index > 0"
                  :key="image.id"
                  class="col-md-4 thumbnail"
                  :src="image.image_url"
                  alt="Imagem do veículo"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal, BaseButton } from '@/components'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'ShowDriverModal',
  props: ['driver_id'],
  data: () => ({
    isOpen: false,
    driver: false,
    vehicles: false,
    vehicle_images: []
  }),
  methods: {
    async openModal () {
      this.isOpen = true
      const {
        data: { data }
      } = await this.$http.get(`/driver/${this.driver_id}`)
      this.driver = data

      const {
        data: { vehicles }
      } = await this.$http.get(`/drivers/${this.driver_id}/vehicles`)
      this.vehicles = vehicles
    }
  },
  components: { Modal, BaseButton }
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.header > img {
  border-radius: 50%;
  width: 200px;
  height: auto;
}

.vehicle > .row > .col-md-8 {
  display: flex;
  flex-direction: column;
}
</style>
